<template>
  <div class="citysetPrice-container">
      <div class="citysetPrice-box">
          <div class="citysetPrice-box-middle">
              <div class="citysetPrice-title">设置服务价格</div>
              <div class="citysetPrice-middle">
                <div class="citysetPrice-center-show">
                    <span></span><span>费用项目说明</span>
                </div>
                <div>
                    <span>协议价格：</span><span>代理人与本平台的签单合作价。</span>
                </div>
                <div class="citysetPrice-center-service">
                    <span>服务价格：</span><span>代理人为客户提供服务时，向客户主张的价格。</span>
                </div>
            </div>
            <div>
                <div class="citysetPrice-center-show" :class="changecitypriceStatus">
                  <div class="citysetPrice-show-div1" @click="changeRisk('trade')">商标业务</div>
                  <div class="citysetPrice-show-div2" @click="changeRisk('patent')">专利业务</div>
                  <div class="citysetPrice-show-div3" @click="changeRisk('copyright')">版权业务</div>
              </div>
            </div>
            <div class="setPrice-ceter-list">
                <div class="list-box" v-for="(v,k) in list" :key='k'>
                <div class="list-title">
                    <span>商品名称</span><span>{{v.goodsName}}</span>
                </div>
                <div class="list-detail">
                    <div>
                        <span>协议价格</span>
                        <span>{{v.proxyPrice | numberFix2}}</span>
                    </div>
                    <div>
                        <span>市场参考价</span>
                        <span>{{v.orignialMin}}~{{v.orignialMax}}元，均价{{v.orignial}}元</span>
                    </div>
                    <div>
                        <span>服务价格</span>
                        <span>{{v.goodsUserdefinedAmt | numberFix2}}</span>
                    </div>
                    <div style="clear:both"></div>
                </div>
                <div class="list-setPrice" @click="set(v.goodsCode,v.orignialMin,v.orignialMax,v.goodsUserdefinedAmt)">设置服务价格</div>
                </div>
            </div>
          </div>
      </div>
      <div class="setPrice-chuang" v-show='chuangShow'>
      <div class="setPrice-form">
         <img :src="require('/static/images/cha.png')" alt="" @click="cha">
         <div class="form-div">
              <div class="set-title">设置服务价格</div>
              <div class="set-middle">
                <div>服务价格</div>
                <div>
                  <input type="text" v-model="servicePrice" onkeyup="value=value.replace(/[^\d]/g,'')">
                  <span>元</span>
                </div>
                <div class="set-range">在市场参考价{{range1}}-{{range2}}元范围内调整，需为整数。</div>
                <div class="set-buttom" @click="changePrice">确定</div>
              </div>
         </div>
       </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'citysetPrice',
  data(){
    return{
      servicePrice:'',
        range1:'',
        range2:'',
        chuangShow:false,
        list:[],
        goodsCode:'',
        changecitypriceStatus:'trade'
    }
  },
  watch:{
       
  },
  components: {

  },
  filters:{
    numberFix2(number){
      if(isNaN(Number(number))) return number
      return number.toFixed(2)
    }
  },
  mounted(){
   this.getData()
  },
  methods:{
      tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:1500
          })
      },
     toagencyCeter(){
         this.$router.push('/agency')
     },
     cha(){
       this.chuangShow =  false;
     },
     set(val1,val2,val3,val4){
       this.chuangShow =  true;
       this.goodsCode = val1,
       this.range1 = val2,
       this.range2 = val3;
       this.servicePrice = val4
     },
     changeRisk(val){
       this.changecitypriceStatus = val;
       this.getData()
     },
     getData(){
      var goodsType = "";
         if(this.changecitypriceStatus== "trade"){
              goodsType = "1"
          }else if(this.changecitypriceStatus== "patent"){
              goodsType = "2"
          }else if(this.changecitypriceStatus== "copyright"){
              goodsType = "3"
          }
       this.$http.post(this.GLOBAL.new_url+'/goods/service/goodsUserDefined/goodsPrice/cityproxy/list',{
              userCode:this.$cookie.getCookie('u_id'),
              goodsType:goodsType
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
              if(res.data.code == "200"){
                  this.list = res.data.data;
              }
            })
     },
     changePrice(){
       if(this.servicePrice>this.range2||this.servicePrice<this.range1){
         this.tip('error','请输入市场参考价格范围内的价格')
       }else{
          this.$http.post(this.GLOBAL.new_url+'/goods/service/goodsUserDefined/goodsPrice/modify',{
              userCode:this.$cookie.getCookie('u_id'),
              goodsCode:this.goodsCode,
              cityServicePrice:this.servicePrice
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
              if(res.data.code == "200"){
                  this.chuangShow =  false;
                  this.getData();
              }
            })
       }
     }
  }
}

</script>

<style scoped>
    .citysetPrice-box{
        width:1015px;
        float: left;
        margin: 12px 0 0 16px;
        background-color: #fff;
        padding-top: 12px;
        min-height: 950px;
        box-sizing: border-box;
    }
    .citysetPrice-box-middle{
        width: 885px;
        margin: 0 auto;
        padding-bottom: 60px;
    }
    .citysetPrice-title{
        height: 32px;
        border-bottom: 1px solid #d8d8d8;
        font-size: 16px;
    }
    .citysetPrice-middle{
        padding-left:4px;
    }
     .citysetPrice-middle>div>span{
    font-size: 14px;
  }
  .citysetPrice-middle>div>span:nth-of-type(1){
    margin-right: 10px;
  }
  .citysetPrice-middle>div>span:nth-of-type(2){
    font-weight: 400;
    color: #676767;
  }
    .citysetPrice-center-show{
        margin: 30px 0 0 0;
        height: 31px;
    }
   .citysetPrice-center-show>div{
     width: 80px;
      text-align: center;
      float: left;
      height: 31px;
      margin-right: 40px;
      cursor: pointer;
   }
   .trade .citysetPrice-show-div1,.patent .citysetPrice-show-div2,.copyright .citysetPrice-show-div3{
    border-bottom: 1px solid #333333;
  }
  .citysetPrice-center-show>span:nth-of-type(1){
    display: inline-block;
    width: 4px;
    height: 14px;
    background: #EB5E00;
    margin-right: 10px;
    vertical-align: middle;
  }
  .citysetPrice-center-show>span:nth-of-type(2){
    font-size: 14px;
    color: #000!important;
    vertical-align: middle;
  }
  .citysetPrice-center-service{
    margin: 16px 0;
  }
  .list-box{
    padding: 30px 0;
    height: 140px;
    border-top:1px solid #D8D8D8;
  }
  .list-title{
    margin-bottom: 16px;
    font-size: 14px;
  }
  .list-title>span:nth-of-type(1){
    color: #000000;
  }
  .list-title>span:nth-of-type(2){
    color:#323232;
    margin-left: 24px;
  }
  .list-detail{
    overflow: hidden;
  }
  .list-detail>div{
    width: 285px;
    float: left;
    font-size: 14px;
    margin-bottom: 16px;
  }
  .list-detail>div>span:nth-of-type(1){
    color: #000000;
  }
  .list-detail>div>span:nth-of-type(2){
    color:#323232;
    margin-left: 24px;
  }
  .list-detail>div:nth-of-type(2){
    width: 395px;
  }
  .list-detail>div:nth-of-type(3){
    width: 205px;
  }
  .list-detail>div:nth-of-type(2)>span:nth-of-type(2){
    margin-left: 10px;
  }
  .list-setPrice{
    width: 124px;
    height: 30px;
    background: #EB5E00;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .setPrice-chuang{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.4);
    z-index: 20;
    top: 0;
    left: 0;
  }
  .setPrice-form{
    width: 600px;
    height: 282px;
    background: #FFFFFF;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%,-50%);
  }
  .setPrice-form>img{
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    height: 23px;
    width: 23px;
  }
  .form-div{
    width: 430px;
    position: absolute;
    top:30px;
    left: 88px;
  }
  .set-title{
    width: 96px;
    margin: 0 auto 30px;
    color: #323232;
    font-size: 16px;
  }
  .set-middle>div:nth-of-type(1){
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 400;
    color: #323232;
  }
  .set-middle>div:nth-of-type(2)>input{
    width: 384px;
    height: 30px;
    outline: none;
    margin-right: 10px;
    padding-left: 12px;
    border-radius: 3px;
    border: 1px solid #D8D8D8;
  }
  .set-middle>div:nth-of-type(2)>span{
    font-size: 14px;
  }
  .set-range{
    font-size: 14px;
    margin: 10px 0 40px;
    font-weight: 400;
    color: #000000;
  }
  .set-buttom{
    width: 108px;
    height: 36px;
    background: #EB5E00;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    margin: 0 auto;
  }
</style>
